$se-icon-folder: "~@se/icons/fonts";
@import './variables.scss';
@import "~bootstrap/scss/bootstrap.scss";
@import "~@se/icons/scss/styles.scss";
$se-fonts-path: "~@se/fonts/fonts";
@import "~@se/fonts/scss/styles.scss";
body {
    font-size: 13px; background: #ffffff !important;
}


/* project page start*/
.card-text{
  color: #0E0F0E;
  font-family: Nunito;
  font-size: 12px;
  font-weight: 300;
  line-height: 14px;
  width:220px;
  text-align: left;
  padding-bottom: 30px;
}

.card-title{
  color: #00D044;
  font-family: Nunito;
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  width: 150px;
  text-align: left;
  
}

.card-text-bold{
    color: #333333;
    font-family: Nunito;
    font-size: 14px;
    line-height: 00px;
    text-align: left;
    }

.card-link{
    float: right;
    padding-top : 14px;

}

.se-font-14px-bold{
    font-size: 14px;
    font-weight:600;
}

.se-font-14px{
    font-size: 14px;
    font-weight:400;
}
.listStyle {
    padding: 12px 0px;
    margin-bottom: 10px;
    cursor: pointer;
    border-radius: 8px;
    background: #ffffff;
}

.listStyle:hover {
    box-shadow: 0px 2px 8px 0px #cccccc;
    //box-shadow: 0px 10px 12px -10px #cccccc;
    //border:1px solid #3DCD58 !important;
}

.border {
    border: 1px solid #dee2e6 !important;
}

.upper {
    text-transform: uppercase;
}

.icon_button {
    margin-right: 8px;
    vertical-align: middle;
    font-size: 24px;
}

.icon_20 {
    font-size: 20px;
}

.icon_24 {
    font-size: 24px;
}

.icon_28 {
    font-size: 28px;
}

.projectTitle {
    border-bottom: 1px solid #ccc;
    display: block;
    padding-bottom: 12px;
}

.inline {
    display: inline;
}

.se-white-background {
    background-color: #ffffff;
}

.se-subHeader {
    font-size: 12px;
}

.se-block-header {
    padding: 12px 12px;
    margin-bottom: 10px;
    cursor: pointer;
    background: #ffffff;
}

.se-text-hr-75 {
    width: 75%;
    display: inline;
    margin-top: 10px;
    position: absolute;
}

.se-text-hr-50 {
    width: 50%;
    display: inline;
    margin-top: 10px;
    position: absolute;
}

.se-small-card {
    padding: 8px 0px 8px 0px;
    margin-bottom: 10px;
    cursor: pointer;
    border-radius: 2px;
    background: #ffffff;
    box-shadow: 0px 1px 2px 0px #cccccc;
    vertical-align: middle;
    line-height: 33px;
    height: 44px;
}

.se-life-green {
    color: $se-life-green;
}

.h-divider {
    height: auto;
    border-left: 1px solid $se-super-light-grey;
    margin: 0px 8px;
}

.se-display-asset-sld {
    width: 100%;
    height: 100%;
}

.version {
    font-size: 14px;
    margin-top: 10px !important;
}

.overlay {
    background-color: #f7f7f7;
    opacity: .9;
}

.blur {
    position: relative;
    font-size: 20px;
    text-align: center;
    display: inline-block
}

.blur:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #f7f7f7;
    filter: blur(5px);
    z-index: 0;
}

.blur span {
    position: relative;
    z-index: 1;
}

.pointer {
    cursor: pointer;
    cursor: url('images/gesture_pointing.svg') 2 2, pointer;
}


/***** Login ******/

.se-form-field.stacked ::slotted(input) {
    width: 100%;
}


/**** old one ****************************************/

.nav-tabs {border-bottom: 1px solid #ffffff;}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {color: $se-life-green; background-color: #fff;  border-top-width: 2px; border-top-style: solid; border-top-color: $se-life-green;}
.nav-tabs .nav-link {border: 1px solid transparent; border-top-left-radius: 2px; border-top-right-radius: 2px; font-size: $font-size-m;}
.nav-tabs>a {color: #212529; background-color: transparent;}


/* project page end */

.hidden {
    display: none;
}

.displayBlack {
    background-color: black;
    color: white;
}

.rdw-editor-toolbar {
    display: none;
}

.se-black-font {
    color: #0f0f0f;
}

.se-deploy-div {
    font-size: 12px;
    font-family: sans-serif;
    vertical-align: middle;
    padding-top: 4px;
    text-align: center;
}

.se-info-icon-custom {
    vertical-align: middle;
}

.custom-control-input:checked~.custom-control-label::before {
    color: #fff;
    border-color: $color-brand;
    background-color: $color-brand;
}

.se-active-grey {
    color: $se-medium-grey;
}

.se-mandatory {
    color: $se-red;
}

.nav-tabs {
    border-bottom: 0px !important;
}


/*---- toggle button css start -----*/

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 2px;
    top: 2px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.slider {
    background-color: #32ad3c;
}

input:focus+.slider {
    box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider:before {
    -webkit-transform: translateX(16px);
    -ms-transform: translateX(16px);
    transform: translateX(16px);
    box-shadow: 0 0 1px white;
}


/* Rounded sliders */

.slider.round {
    border-radius: 13px;
    height: 20px;
    width: 35px;
    background-color: #ffffff;
}

.slider.round:before {
    border-radius: 55%;
    box-shadow: 0 0 1px #000000;
}


/*---- toggle button css end -----*/

@media only screen and (min-width: 1201px) and (max-width: 1280px) {
.pl-3{padding-left: 0.3rem !important;}
}